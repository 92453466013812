import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

import { Splide } from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href^="#"]', {offset: 0})

  $(document).scroll(function () {
    const scroll = $(this).scrollTop()
    if ($(window).width() >= 991 && scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })
})

document.addEventListener('DOMContentLoaded', function () {
  const isMobile = $(window).width() <= 1024
  const levels = ['.header-nav-list > li', '.second-level > li', '.third-level > li', '.fourth-level > li']
  const headerContainer = document.querySelector('.header')

  function toggleOpen (item) {
    item.classList.toggle('open')
    Array.from(item.parentElement.children).forEach(function (otherItem) {
      if (otherItem !== item && otherItem.classList.contains('open')) {
        otherItem.classList.remove('open')
      }
    })
  }

  levels.forEach(function (selector) {
    document.querySelectorAll(selector).forEach(function (item) {
      if (isMobile) {
        if (item.classList.contains('no-sub')) {
          item.addEventListener('click', function (event) {
            event.stopPropagation()
            toggleOpen(item)
          })

          item.addEventListener('touchstart', function (event) {
            if (event.target.tagName.toLowerCase() !== 'a') {
              event.preventDefault()
              event.stopPropagation()
              toggleOpen(item)
            }
          })
        } else {
          // Ersetzen des Links durch ein span-Element
          const link = item.querySelector('a')
          if (link) {
            const span = document.createElement('span')
            span.textContent = link.textContent
            span.title = link.title
            item.replaceChild(span, link)
          }

          // Event-Listener für den Klick auf das neue Span-Element
          item.addEventListener('click', function (event) {
            event.stopPropagation()
            toggleOpen(item)
          })

          item.addEventListener('touchstart', function (event) {
            if (event.target.tagName.toLowerCase() !== 'a') {
              event.preventDefault()
              event.stopPropagation()
              toggleOpen(item)
            }
          })
        }
      } else {
        item.addEventListener('mouseenter', function () {
          document.querySelectorAll(selector).forEach(function (otherItem) {
            otherItem.classList.remove('open')
          })
          item.classList.add('open')
        })

        if (selector === '.header-nav-list > li') {
          headerContainer.addEventListener('mouseleave', function () {
            document.querySelectorAll(selector).forEach(function (otherItem) {
              otherItem.classList.remove('open')
            })
          })
        }
      }
    })
  })
})

document.addEventListener('DOMContentLoaded', function () {
  const element = document.querySelector('.partner-slider')
  const elementInfo = document.querySelector('.information-slider')
  const elementThumbnail = document.querySelector('.thumbnail-slider')
  if (element) {
    console.log('Element gefunden:', element)
    new Splide('.splide.partner.autoscroll', {
      type: 'loop',
      drag: 'free',
      perPage: 8,
      pagination: false,
      arrows: false,
      autoScroll: {
        speed: 1
      },
      mediaQuery: 'max',
      breakpoints: {
        600: {
          perPage: 4
        }
      }
    }).mount(window.splide.Extensions)
  } else {
    console.log('Kein Element mit der Klasse "partner-slider" gefunden.')
  }

  if (elementInfo) {
    console.log('Element gefunden:', elementInfo)
    const splide = new Splide('.splide.information', {
      perPage: 2,
      perMove: 2,
      gap: 20,
      mediaQuery: 'max',
      breakpoints: {
        1200: {
          padding: '1.2rem'
        },
        990: {
          perPage: 1,
          perMove: 1,
          padding: '1rem'
        }
      }
    })
    splide.mount()
  } else {
    console.log('Kein Element mit der Klasse "partner-slider" gefunden.')
  }

  if (elementThumbnail) {
    console.log('Element gefunden:', elementThumbnail)
    const sliderContainers = document.querySelectorAll('.thumbnail-slider--wrapper')

    sliderContainers.forEach(function (container) {
      const mainCarousel = container.querySelector('.thumbnail-main-slider')
      const thumbnailCarousel = container.querySelector('.thumbnail-slider')

      if (mainCarousel && thumbnailCarousel) {
        const main = new Splide(mainCarousel, {
          type: 'fade',
          rewind: true,
          pagination: false,
          arrows: false
        })

        const thumbnails = new Splide(thumbnailCarousel, {
          fixedWidth: 100,
          fixedHeight: 88,
          gap: 10,
          rewind: true,
          pagination: false,
          isNavigation: true,
          mediaQuery: 'max',
          breakpoints: {
            1024: {
              fixedWidth: 60,
              fixedHeight: 44
            }
          }
        })

        main.sync(thumbnails)
        main.mount()
        thumbnails.mount()
      }
    })
  } else {
    console.log('Kein Element mit der Klasse "thumbnail-slider" gefunden.')
  }
})

document.addEventListener('DOMContentLoaded', function () {
  const panels = document.querySelectorAll('.information--wrapper')
  panels.forEach(function (panel, index) {
    const id = 'readmore' + (index + 1)
    const desContainer = panel.querySelector('.des')
    const contentHeight = desContainer.scrollHeight
    panel.querySelector('input[name="readmore"]').setAttribute('id', id)
    panel.querySelector('label').setAttribute('for', id)
  })
})

document.addEventListener('DOMContentLoaded', function () {
  const panels = document.querySelectorAll('.information--wrapper')

  function adjustContentHeight (panel) {
    const input = panel.querySelector('input[name="readmore"]')
    const desContainer = panel.querySelector('.des')

    if (input.checked) {
      const contentHeight = desContainer.scrollHeight
      desContainer.style.maxHeight = contentHeight + 'px'
    } else {
      desContainer.style.maxHeight = '435px'
    }
  }

  function adjustAllContentHeights () {
    panels.forEach(function (panel) {
      adjustContentHeight(panel)
    })
  }

  // Initial anpassen
  adjustAllContentHeights()

  // Anpassen, wenn das Fenster skaliert wird
  window.addEventListener('resize', function () {
    adjustAllContentHeights()
  })

  // Anpassen, wenn der Nutzer das Input-Element auswählt/abwählt
  panels.forEach(function (panel) {
    const input = panel.querySelector('input[name="readmore"]')
    input.addEventListener('change', function () {
      adjustContentHeight(panel)
    })
  })
})
